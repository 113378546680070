<template>
    <div class="status-box">
        <div :class="['aes-box', getStateClass]">
            <div class="aes-name">{{ item.AES_NM || '' }}</div>
        </div>
        <div class="aes-content">
            <div class="arrow" v-if="item.SENT">
                <img width="8px" height="12px" :src="require('../../../../../assets/images/up_arrow.png')" /> {{ item.SENT.toLocaleString() }}
            </div>
            <div class="arrow" v-if="item.RCVD">
                <img width="8px" height="12px" :src="require('../../../../../assets/images/down_arrow.png')" /> {{ item.RCVD.toLocaleString() }}
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
       item: {
           type: Object
       }
    },
    computed: {
        getStateClass() {
            return this.item.STATE === 'established' ? 'success' : 'error';
        }
    }
}

</script>
<style scoped>
.status-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 3px 10px 3px 10px;
}
.aes-box {
    display: flex;
    border: 1px solid #dfe3e8;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 60px;
}
.aes-box {
    background-repeat: no-repeat;
    background-size: cover;
}
/* class명 getStateClass에서 동적으로 사용중 */
.aes-box.success {
    background-image: url("../../../../../assets/images/aes_established.png");
}
/* class명 getStateClass에서 동적으로 사용중 */
.aes-box.error {
    background-image: url("../../../../../assets/images/aes_error.png");
}
.aes-name {
    color: #fff;
    margin-left: 20px;
    font-weight: bold;
}
.aes-content {
    display: flex;
    margin-top: 2px;
}
.arrow {
    display: flex;
    margin-left: 2px;
    margin-right: 2px;
    align-items: center;
    justify-content: center;
}
</style>